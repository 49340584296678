import axios from "axios";
import React, { Component } from "react";
import Loader from "react-loader-spinner";
import AppConstants from "../../../Constants/AppConstants";
import Sidebar from "../../Sidebar";
import "./Enrollments.css";
import { Fade } from "react-awesome-reveal";

class Enrollments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enrollments: [],
      loading: true,
      exams: [],
    };
    this.getEnrollments();
  }

  studentprofile() {
    this.props.history.push("/studentprofile");
  }

  enrolledexams() {
    this.props.history.push("/enrolled-exams");
  }

  getExam(exam_id) {
    console.log("hello here" + exam_id);
    this.props.history.push("/exam-history?enrollmentId=" + exam_id);
    console.log("hello here again" + exam_id);
  }

  studentSubscriptions() {
    this.props.history.push("/transactions");
  }

  async getEnrollments() {
    try {
      const response = await axios.get("/api/students/enrollments");
      console.log(response.data);
      let enrollments = response.data.enrollments;
      this.setState({ enrollments });

      const examRequests = enrollments.map(async (element) => {
        if (!element) {
          // Skip empty elements
          return null;
        }

        try {
          const examResponse = await axios.get(
            "/api/active-exams/" + element.exam_id
          );
          let exam = examResponse.data;
          let imgUrl = AppConstants.IMG_URL + exam.exam_image;
          exam.exam_image = imgUrl;
          exam.enrollment_id = element.enrollment_id;

          if (exam.exam_desc.length > 240) {
            let str = exam.exam_desc.slice(0, 237);
            let newstr = str + "...";
            exam.exam_desc = newstr;
          }

          return exam;
        } catch (error) {
          if (axios.isCancel(error)) {
            // Axios request was canceled, no need to handle errors
          } else if (error.response && error.response.status === 404) {
            // Handle 404 error (exam not found) by returning null
            return null;
          } else {
            // Handle other errors
            console.error("Error fetching enrollments:", error);
          }
        }
      });

      const exams = await Promise.all(examRequests);
      const filteredExams = exams.filter((exam) => exam !== null); // Filter out null results (failed fetch)

      this.setState({ exams: filteredExams, loading: false });
    } catch (error) {
      console.error("Error fetching enrollments:", error);
    }
  }

  render() {
    if (this.state.loading) {
      return (
        <Loader
          className="text-center"
          type="Puff"
          color="#00BFFF"
          height={500}
          width={100}
          timeout={5000}
        />
      );
    } else {
      return (
        <div className="container">
          <div className="row">
            <Sidebar />
            <div className="conatiner col-lg-10 col-md-10 col-sm-12 col-xs-12">
              <div className="repo-cards-div-main">
                {this.state.exams.map((exam, i) => {
                  return (
                    <div key={i} className="repo-card-div">
                      <Fade bottom duration={2000} distance="40px">
                        <div>
                          <div
                            key={i + "key"}
                            onClick={() => {
                              this.getExam(exam.enrollment_id);
                            }}
                          >
                            <div className="repo-name-div">
                              <object
                                data="/images/test-icon.svg"
                                width="25"
                                height="25"
                              >
                                {" "}
                              </object>
                              <p className="repo-name"> {exam.exam_title}</p>
                            </div>
                            <p className="repo-description">{exam.exam_desc}</p>
                            <div className="repo-details">
                              <p className="repo-duration">
                                Access:{" "}
                                {exam.exam_mode === "public"
                                  ? "Premium"
                                  : exam.exam_mode === "private"
                                  ? "Premium+"
                                  : "Free"}
                              </p>
                              <p className="repo-duration">
                                Questions: {exam.number_of_questions}
                              </p>
                              <p className="repo-duration">
                                Time: {exam.exam_duration} Mins
                              </p>
                              <p className="repo-duration">
                                Marks: {exam.total_exam_score}
                              </p>
                            </div>
                          </div>
                        </div>
                      </Fade>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default Enrollments;
