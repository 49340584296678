import React, { Component } from "react";
import "./Categories.css";
import axios from "axios";

import AppConstants from "../../Constants/AppConstants";

export default class CategoriesPublic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cats: [],
      catagoryDetails: {},
      finalCats: [],
      options: {},
      list: {},
      catEmpty: false,
      imageUrl: "",
      isTablet: false,
    };
  }

  async componentDidMount() {
    this.updateIsTablet();
    window.addEventListener("resize", this.updateIsTablet);

    let imageUrl = AppConstants.IMG_URL;
    this.setState({ imageUrl });
    axios({
      method: "GET",
      url: "/api/popular?type=categories&top=10",
    }).then((response) => {
      let resp = response.data;
      console.log(resp);
      let array = [];
      let catArray = resp.popular.categories;
      if (catArray === "") {
        this.setState({ catEmpty: true });
      } else {
        catArray.forEach((element) => {
          let object = {
            category: element.category_name,
            category_id: element.category_id,
            category_image: element.category_image,
          };
          array.push(object);
          this.setState({ finalCats: array });
        });
      }
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateIsTablet);
  }

  updateIsTablet = () => {
    const screenWidth = window.innerWidth;
    const isTablet = screenWidth >= 600 && screenWidth <= 980;
    this.setState({ isTablet });
    console.log(isTablet);
  };

  async getCategory(catagory_id) {
    await axios({
      method: "GET",
      url: "/api/catagories/" + catagory_id,
    })
      .then((response) => {
        this.setState({ catagoryDetails: response.data });
      })
      .catch((error) => {
        console.log(error.response.data.errors[0].message);
      });
  }

  render() {
    return (
      <>
        {this.state.catEmpty === false ? (
          <div className="container">
            <div className=" mr-4 top-educators ">
              <div className="row">
                <div className="col-md-9 top-educators-left">
                  <h5 className="font-mob">Popular Categories</h5>
                  <hr></hr>
                  <p className="para-mob">
                    Access multiple exams under popular categories
                  </p>
                </div>
                <div className="col-md-3 top-educators-right">
                  <br />
                  <br />
                  <a className="text-decoration-none" href="/categories">
                    <p className="card-seeall">View More</p>
                  </a>
                </div>
              </div>
              <br />
            </div>
            <div className="container">
              <div className="row">
                {!this.state.finalCats
                  ? "Loading..."
                  : this.state.finalCats.map((cat) => {
                      return (
                        <div
                          className={
                            this.state.isTablet
                              ? "col-6 col-md-4 my-3"
                              : "col-md-2 my-3"
                          }
                          key={cat.category_id}
                        >
                          <div
                            className="card mt"
                            href={
                              "/categorypublicpage?categoryId=" +
                              cat.category_id
                            }
                          >
                            <a
                              className="text-decoration-none"
                              href={
                                "/categorypublicpage?categoryId=" +
                                cat.category_id
                              }
                            >
                              <div className="card-image">
                                <figure className="image">
                                  <img
                                    src={
                                      this.state.imageUrl + cat.category_image
                                    }
                                    alt="category"
                                    height={
                                      AppConstants.POPULAR_CATEGORIES_HEIGHT
                                    }
                                    width={
                                      AppConstants.POPULAR_CATEGORIES_WIDTH
                                    }
                                  />
                                </figure>
                              </div>
                              <div>
                                <p className="text-dark text-center">
                                  {cat.category}
                                </p>
                              </div>
                            </a>
                          </div>
                        </div>
                      );
                    })}
              </div>
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </>
    );
  }
}
