import React, { useState, useEffect } from "react";
import "./PopularGoals.css";
import { useHistory } from "react-router-dom";
import { TypeAnimation } from "./Transitions/TypeAnimation";

export default function PopularGoals({
  lightBg,
  lightText,
  addgoals,
  imgStart,
}) {
  const history = useHistory();
  const [domains, setDomains] = useState(null);

  // State for cycling words
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const words = ["Exam Readiness", "Investing Mindset", "Trading Psychology"];

  // Cycle through words every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 5000);
    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  useEffect(() => {
    setTimeout(
      () =>
        fetch("/api/popular?type=domains&top=6")
          .then((results) => results.json())
          .then((data) => {
            setDomains(data.popular);
          }),
      2000
    );
  }, []);

  return (
    <div
      className={lightBg ? "home__hero-section" : "home__hero-section darkBg"}
    >
      <div className="container">
        <div
          className="row mt-1"
          style={{
            display: "flex",
            flexDirection: imgStart === "start" ? "row-reverse" : "row",
          }}
        >
          <div className="col-xs-12 col-md-12 col-lg-6 col-sm-10 ">
            <br />
            <br />
            <div className="home__hero-text-wrapper">
              {/* Dynamic Heading */}
              <h5 className="home-h4">
                Elevate Your {words[currentWordIndex]}
              </h5>
              <TypeAnimation className="typeanimation" />;
              <br />
              {addgoals ? (
                <div className="goalstab mt-3" style={{ paddingleft: "0px" }}>
                  <h5
                    className={
                      lightText ? "heading" : "heading dark textres--mob"
                    }
                  >
                    Popular Goals
                  </h5>
                  <div className="row">
                    <br></br>
                    {!domains || !domains?.domains
                      ? "Loading..."
                      : domains.domains.map((data) => (
                          <a
                            className="bottom-mob col-lg-6 col-md-6 text-dark text-decoration-none"
                            key={data.domain_name}
                            onClick={() => {
                              sessionStorage.setItem(
                                "domainSelected",
                                data.domain_id
                              );
                              sessionStorage.setItem(
                                "domainNameSelected",
                                data.domain_name
                              );
                              history.push({
                                pathname: "/domain-wise-categories",
                                search: `?search=${data.domain_id}`,
                              });
                            }}
                          >
                            <div className="card home-card mt-1 mb-4">
                              {data.domain_name}
                            </div>
                          </a>
                        ))}
                  </div>
                </div>
              ) : (
                <h1> </h1>
              )}
            </div>
          </div>
          <div className="col-xs-12 col-md-12 col-lg-6 col-sm-12 text-center d-none d-lg-block">
            {/* xs (phones), sm (tablets), md (desktops), and lg (larger desktops) */}
            <img height="100%" src="/images/home3.svg" alt="ExamArc" />
          </div>
        </div>
      </div>
    </div>
  );
}
