import React, { Component } from "react";
import axios from "axios";
import "./InstructorPage.css";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import AppConstants from "../../../Constants/AppConstants";
import { Fade } from "react-awesome-reveal";
import { Accordion, Card, Button } from "react-bootstrap"; // Import Bootstrap components

class InstructorPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: JSON.parse(sessionStorage.getItem("userData")),
      usersType: sessionStorage.getItem("userType"),
      isRegistered: sessionStorage.getItem("isInstructor"),
      instructorRedirect: false,
      instructorList: [],
      instructor: {},
      finalInstructors: [],
      final: [],
      examsList: [],
      loading: true,
      verified: false,
      image: AppConstants.IMG_URL,
      containsStockStudy: false,
      socialmedia: {},
      activeKey: null,
    };
  }
  openRepoinNewTab(url) {
    var win = window.open(url, "_blank");
    win.focus();
  }

  componentDidMount() {
    let search = new URLSearchParams(this.props.location.search);
    if (search) {
      let instId = search.get("instructorId");
      if (instId) {
        this.getUser(instId);
        this.getExams(instId);
        this.getBlogs(instId);
      }
    }
    if (this.state.userDetails) {
      this.setState({ loginStatus: true });
    } else {
      this.setState({ loginStatus: false });
    }

    // Set the initial collapse/expand state for each category
    const initialCategoryCollapse = {};
    this.state.examsList.forEach((exam) => {
      initialCategoryCollapse[exam.catagory_id] = true;
    });
    this.setState({ categoryCollapse: initialCategoryCollapse });
  }

  checkUser() {
    axios({
      method: "GET",
      url: "/api/users/currentuser",
    })
      .then((response) => {
        if (response.data.current_user === null) {
          // swal("Please login first");
          this.props.history.push("/signin");
        } else {
          this.getUser();
          console.log(JSON.stringify(response.data));
        }
      })
      .catch((error) => {
        console.log(error.response.data.errors[0].message);
      });
  }

  getUser(instId) {
    axios({
      method: "GET",
      url: "/api/users/instructors/" + instId,
    })
      .then((response) => {
        let instructor = response.data;
        let image = AppConstants.IMG_URL + instructor.profile_image;
        instructor.profile_image = image;
        this.setState({ instructor });
        let socialmedia = instructor.socialmedia;
        this.setState({ socialmedia });
        console.log("data:" + JSON.stringify(this.state.socialmedia));
      })
      .catch((error) => {
        console.log(error.response.data.errors[0].message);
      });
  }

  // New function to fetch blogs
  async getBlogs(instId) {
    try {
      // Use the catId dynamically from the URL parameter
      const response = await axios.get(
        `/api/blogs/v1?blog_creator=${instId}&status=active`
      );

      if (response.data) {
        const blogData = this.organizeBlogs(response.data.blogs);
        this.setState({ blogData });
      }
    } catch (error) {
      console.log("Error fetching blogs:", error.message);
    }
  }

  // Helper function to organize blogs into a domain-category structure
  organizeBlogs(blogs) {
    let blogData = {};
    blogs.forEach((blog) => {
      const domainName = blog.domain_name || "Other Domain";
      const categoryName = blog.catagory_name || "Other Category";

      if (!blogData[domainName]) {
        blogData[domainName] = {};
      }

      if (!blogData[domainName][categoryName]) {
        blogData[domainName][categoryName] = [];
      }

      blogData[domainName][categoryName].push(blog);
      // Check for "Stock Study" domain name
      if (domainName === "Stock Study") {
        this.containsStockStudy = true;
      }
    });

    console.log(blogData);
    return blogData;
  }

  getExams(instId) {
    axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      url: "/api/active-exams?exam_creator_id=" + instId,
    })
      .then((response) => {
        this.setState({ loading: false });
        let examsList = response.data.exams;
        this.setState({ examsList });
      })
      .catch((error) => {
        console.log(error.response.data.errors[0].message);
      });
  }

  getExam(exam_id) {
    this.props.history.push("/exampage?examId=" + exam_id);
  }

  enrollForBlog(blogId) {
    window.open(`/blog/${blogId}`, "_blank");
  }

  toggleCategory(categoryId) {
    this.setState((prevState) => ({
      activeCategory:
        prevState.activeCategory === categoryId ? null : categoryId,
    }));
  }

  render() {
    if (this.state.loading) {
      return (
        <Loader
          className="text-center"
          type="Puff"
          color="#00BFFF"
          height={500}
          width={100}
          timeout={5000}
        />
      );
    } else {
      // Extract unique categories
      const uniqueCategories = Array.from(
        new Set(this.state.examsList.map((exam) => exam.catagory_id))
      );

      // Check if blogData exists and has content
      const { blogData } = this.state;

      return (
        <div className="container">
          <div className="basic-projects">
            <Fade bottom duration={2000} distance="40px">
              <div className="projects-heading-text-div">
                <div className="avatar">
                  <img
                    src={this.state.instructor.profile_image}
                    alt="category"
                  />
                </div>
                <div>
                  <h3 className="projects-heading-text">
                    {this.state.instructor.first_name}{" "}
                    {this.state.instructor.last_name}
                  </h3>
                  <p className="projects-header-detail-text subTitle">
                    {this.state.instructor.bio}
                  </p>
                </div>
                {this.containsStockStudy && (
                  <div className="disclaimer">
                    <p>
                      Disclaimer: The information provided in the Stock Study
                      section is for educational purposes only and does not
                      constitute financial advice.
                    </p>
                  </div>
                )}
              </div>
            </Fade>
          </div>

          {/* Exam Listing Section */}
          <div className="container">
            <div className="row text-center mt-4">
              <h5 className="edu-title mx-auto">
                Topics by {this.state.instructor.first_name}{" "}
                {this.state.instructor.last_name}
              </h5>
            </div>
            <hr />
            <div>
              {uniqueCategories.map((categoryId) => {
                const categoryExams = this.state.examsList.filter(
                  (exam) => exam.catagory_id === categoryId
                );

                return (
                  <div key={categoryId + "category"}>
                    <div
                      className={`category-item ${
                        this.state.activeCategory === categoryId ? "active" : ""
                      }`}
                      onClick={() => this.toggleCategory(categoryId)}
                    >
                      <b>
                        {this.state.activeCategory === categoryId ? (
                          <span>- </span>
                        ) : (
                          <span>+ </span>
                        )}
                        {categoryExams[0].catagory_name}{" "}
                      </b>

                      <div
                        className={`exams-list ${
                          this.state.activeCategory === categoryId
                            ? "expanded"
                            : "collapsed"
                        } `}
                      >
                        <hr style={{ margin: "5px 0", width: "100%" }} />
                        {categoryExams.map((filteredExam, index) => (
                          <div
                            className="repo-cards-div-main"
                            key={filteredExam.exam_id}
                            onClick={() => this.getExam(filteredExam.exam_id)}
                          >
                            <Fade bottom duration={2000} distance="40px">
                              <object
                                data="/images/test-icon.svg"
                                width="25"
                                height="25"
                              >
                                {" "}
                              </object>
                              <p className="repo-durations">
                                {filteredExam.exam_title}
                              </p>

                              <p className="repo-durations">
                                {filteredExam.exam_subtitle}
                              </p>

                              <p className="repo-durations">
                                Questions: {filteredExam.number_of_questions}
                              </p>
                              <p className="repo-duration">
                                Time: {filteredExam.exam_duration} Mins
                              </p>
                              <p className="repo-duration">
                                Marks: {filteredExam.total_exam_score}
                              </p>
                              <p className="repo-duration">
                                Access:{" "}
                                {filteredExam.exam_mode === "public"
                                  ? "Premium"
                                  : filteredExam.exam_mode === "private"
                                  ? "Premium+"
                                  : "Free"}
                              </p>
                            </Fade>
                            {index !== categoryExams.length - 1 && (
                              <hr style={{ margin: "5px 0", width: "100%" }} />
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <hr />

            {/* Blog Listing Section */}
            <Fade bottom duration={5000} distance="40px">
              <Accordion defaultActiveKey="0">
                {blogData &&
                  Object.keys(blogData).map((domainName, domainIdx) => {
                    const domainBlogs = blogData[domainName] || {};
                    return Object.keys(domainBlogs).map(
                      (categoryName, categoryIdx) => {
                        const categoryBlogs = domainBlogs[categoryName].filter(
                          (blog) => blog.blog_status === "active"
                        );

                        if (categoryBlogs.length === 0) return null;

                        // Track if the current section is expanded or collapsed
                        const isExpanded =
                          this.state.activeKey ===
                          `${domainIdx}-${categoryIdx}`;

                        return (
                          <Card key={`${domainIdx}-${categoryIdx}`}>
                            <Card.Header
                              style={{
                                backgroundColor: "#212529",
                                padding: "8px 10px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {/* + or - symbol to indicate expand/collapse */}
                              <span
                                style={{
                                  marginRight: "10px",
                                  fontSize: "1.5rem",
                                  color: "#007bff",
                                  cursor: "pointer",
                                }}
                              >
                                {isExpanded ? "−" : "+"}
                              </span>

                              <Accordion.Toggle
                                as={Button}
                                variant="link"
                                eventKey={`${domainIdx}-${categoryIdx}`}
                                style={{
                                  fontSize: "1rem",
                                  color: "#007bff",
                                  textDecoration: "none",
                                  padding: "0",
                                  textAlign: "left",
                                  width: "100%",
                                }}
                                onClick={() =>
                                  this.setState({
                                    activeKey: isExpanded
                                      ? null
                                      : `${domainIdx}-${categoryIdx}`,
                                  })
                                } // Toggle the active section
                              >
                                <p className="repo-name">{`${domainName} : ${categoryName}`}</p>
                              </Accordion.Toggle>
                            </Card.Header>

                            <Accordion.Collapse
                              eventKey={`${domainIdx}-${categoryIdx}`}
                            >
                              <Card.Body>
                                {categoryBlogs.map((blog, blogIdx) => (
                                  <div key={blogIdx} className="blog-item">
                                    <div
                                      onClick={() =>
                                        this.enrollForBlog(blog.blog_id)
                                      } // Call enrollForBlog instead of getBlogPage
                                    >
                                      <div className="repo-name-div">
                                        <object
                                          data="/images/test-icon.svg"
                                          width="25"
                                          height="25"
                                        ></object>
                                        <p className="repo-name">
                                          <span
                                            style={{
                                              color: "darkblue",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            {blog.blog_title}
                                          </span>{" "}
                                          | Access:{" "}
                                          {blog.blog_mode
                                            .charAt(0)
                                            .toUpperCase() +
                                            blog.blog_mode.slice(1)}{" "}
                                          | Creation Date:{" "}
                                          {new Date(
                                            blog.creation_date
                                          ).toLocaleDateString("en-GB")}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        );
                      }
                    );
                  })}
              </Accordion>
            </Fade>
          </div>
        </div>
      );
    }
  }
}

export default InstructorPage;
