import React, { Component } from "react";
import axios from "axios";
import "./InstructorExams.css";
import { Modal } from "react-bootstrap";
import Pagination from "react-js-pagination";
import Loader from "react-loader-spinner";
import swal from "sweetalert";
import AppConstants from "../../../Constants/AppConstants";
import Sidebar from "../../Sidebar";

export default class InstructorExams extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      showHide: false,
      examResponse: [],
      assistants: [],
      assistantsEmail: "",
      exam_id: "",
      pagination: {
        pageNo: 1,
        count: 10,
        search: "",
        status: "",
        items: "",
        totalPage: 5,
        catagory: "",
        domain: "",
      },
      examDetails: {},
      examDomainDetails: {},
      examCategoryDetails: {},
      questionsList: [],
      answerSet: [],
      tempList: [],
      creating: false,
      active: false,
      disabled: false,
      approved: false,
      waiting: false,
      competitive: false,
      public: false,
      mcq: false,
      domainDataList: [],
      categoryDataList: [],
      errors: {},
      redirect: false,
      loadig: true,
      newMessage: {},
      messagesHistory: [],
      chatExam: "",
      showMessage: [],
      message: {
        message: "",
        subject: "",
        direction: "",
        from: {},
        to: {},
      },
      sender: {
        id: "",
        email: "",
        first_name: "",
        last_name: "",
        type: "",
      },
      reciver: {
        type: "admin",
      },
      newMessageflag: false,
      dataMessage: {},
      ImgUrl: AppConstants.IMG_URL,
    };
  }

  handleModalShowHide(exam_id) {
    this.setState({ exam_id });
    if (exam_id !== 0) {
      this.getAssistantsList(exam_id);
    } else {
      this.setState({ showHide: false });
    }
  }

  getAssistantsList(examId) {
    let assistants = [];
    this.setState({ assistants });
    if (examId) {
      axios({
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
        url: "/api/examstore/" + examId + "/assistants",
      })
        .then((response) => {
          let assistants = [];
          let assistantsresp = response.data.assistants;
          if (assistantsresp && assistantsresp.length > 0) {
            for (let i = 0; i < assistantsresp.length; i++) {
              if (assistantsresp[i].validity !== "disabled") {
                assistants.push(assistantsresp[i]);
              }
            }
            this.setState({ assistants });
          }
          this.setState({ showHide: !this.state.showHide });
        })
        .catch((error) => {
          console.log(error.response.data.errors[0].message);
        });
    }
  }

  componentDidMount() {
    this._isMounted = true;
    this.checkUser();
    this.getExamList();
    let user = JSON.parse(sessionStorage.getItem("userData"));
    this.setState({ userData: user });
    let name = user.first_name;
    this.setState({ name: name });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async checkUser() {
    axios({
      method: "GET",
      url: "/api/users/currentuser",
    })
      .then((response) => {
        if (response.data.current_user === null) {
          swal("Please login first");
          this.props.history.push("/signin");
        } else {
          console.log("logged in");
        }
      })
      .catch((error) => {
        if (this._isMounted) {
          console.log(error.response.data.errors[0].message);
        }
      });
  }

  async getExamList() {
    let url = "/api/examstore";
    let instructorData = JSON.parse(sessionStorage.getItem("userData"));
    if (instructorData.user_id) {
      url = url + "?exam_creator=" + instructorData.user_id;
    }
    if (this.state.pagination.search !== "") {
      url = url + "&search_key=" + this.state.pagination.search;
    }
    if (this.state.pagination.pageNo !== "") {
      url = url + "&page_no=" + this.state.pagination.pageNo;
    }
    if (this.state.pagination.count !== "") {
      url = url + "&count=" + this.state.pagination.count;
    }
    if (this.state.pagination.status !== "") {
      url = url + "&status=" + this.state.pagination.status;
    }
    if (this.state.pagination.catagory !== "") {
      url = url + "&catagory=" + this.state.pagination.catagory;
    }
    if (this.state.pagination.domain !== "") {
      url = url + "&domain=" + this.state.pagination.domain;
    }
    axios({
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
      url: url,
    })
      .then((response) => {
        this.setState({ examResponse: [] });
        this.setState({ loading: false });
        let page = this.state.pagination;
        page.items = response.data.total_exams_count;
        page.pageNo = response.data.page_no;
        page.totalPage = response.data.total_no_pages;
        page.count = response.data.count_per_page;
        this.setState({ pagination: page });
        console.log(response, "result in first function");
        if (response.status === 200 || response.status === 201) {
          if (response.data.exams && response.data.exams.length > 0) {
            const filteredExams = response.data.exams.filter(
              (exam) => exam.exam_desc != "blogpost"
            );
            this.setState({ examResponse: filteredExams });
            this.getExamAndUpdateImage(filteredExams);
          } else {
            console.log("Details not found");
          }
        } else {
          console.log("Details not found");
        }
      })
      .catch((error) => {
        if (this._isMounted) {
          console.log(error.response.data.errors[0].message);
        }
      });
  }

  getExamAndUpdateImage(examList) {
    let exams = [];
    examList.forEach((element) => {
      let exam = element;
      axios({
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
        url: "/api/examstore/" + element.exam_id,
      }).then((response) => {
        console.log("exam" + JSON.stringify(response.data?.exam_image));
        if (response.data?.exam_image)
          exam.exam_image = response.data?.exam_image;
        exams.push(exam);
        this.setState({ examResponse: exams });
      });
    });
  }

  onSearch(e) {
    let pagination = this.state.pagination;
    pagination.search_key = e.target.value;
    this.setState({ pagination });
    this.getExamList();
  }

  modifyExam(examId) {
    this.props.history.push("/createexam?examId=" + examId);
  }

  submitExamForApproval(examId) {
    axios({
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      url: "/api/examstore/" + examId + "/approvalreqs",
    })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          this.componentDidMount();
          console.log("Exam submitted for approval");
        } else {
          console.log("Details not found");
        }
      })
      .catch((error) => {
        console.log(error.response.data.errors[0].message);
      });
  }

  deleteExam(examId) {
    axios({
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      url: "/api/examstore/" + examId + "/delete",
    })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          this.componentDidMount();
          console.log("Exam deleted successfully");
        } else {
          console.log("Details not found");
        }
      })
      .catch((error) => {
        console.log(error.response.data.errors[0].message);
      });
  }

  instructionDataChange(field, e) {
    this.setState({ assistantsEmail: e.target.value });
  }

  changeAssistantStatus(email, methodType, action) {
    let data = {
      email: [],
    };
    data.email.push(email);
    axios({
      method: methodType,
      headers: {
        "Content-Type": "application/json",
      },
      url: "/api/examstore/" + this.state.exam_id + "/assistants",
      data: data,
    })
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          this.getAssistantsList(this.state.exam_id);
          let msg = "Assistant " + action + " successfully....";
          console.log(msg);
        }
      })
      .catch((error) => {
        console.log(error.response.data.errors[0].message);
      });
  }

  handlePageChange(pageNumber) {
    let pagination = this.state.pagination;
    pagination["pageNo"] = pageNumber;
    this.setState({ pagination: pagination });
    this.getExamList();
  }

  pagination(field, e) {
    let pagination = this.state.pagination;
    pagination[field] = e.target.value;
    this.setState({ pagination: pagination });
    this.getExamList();
  }

  async getExamById(examId) {
    const { data: response } = await axios
      .get("api/examstore/" + examId)
      .catch((error) => {
        console.log(error);
        console.log(error.response.data.errors[0].message);
      });
    this.setState({ examDetails: response });
    this.setState({ examData: this.state.examDetails });

    console.log(this.state.examDetails);
    this.setState({ examDomainDetails: response.domain });
    this.setState({ examCategoryDetails: response.catagory });
    this.setState({ questionsList: response.exam_questions });
    this.setState({ answerSet: response.answer_set });
    let Data = this.state.questionsList;
    //this.state.tempList = this.state.answerSet;
    this.setState({ tempList: response.answer_set });
    Data.forEach((element, i) => {
      element.correct_answer = this.state.tempList[i].correct_answer;
      element.reason = this.state.tempList[i].reason;
    });
    this.setState({ questionsList: Data });
  }

  getExamChat(exam_id) {
    sessionStorage.setItem("exam_id", exam_id);
    this.props.history.push("/inbox?examId=" + exam_id);
  }

  myExams() {
    this.props.history.push("/instructor-exams");
  }

  createExam() {
    this.props.history.push("/createexam");
  }

  instructorlanding() {
    this.props.history.push("/instructorlanding");
  }

  render() {
    if (this.state.loading) {
      return (
        <Loader
          className="text-center"
          type="Puff"
          color="#00BFFF"
          height={500}
          width={100}
          timeout={5000}
        />
      );
    } else {
      return (
        <div className="container">
          {/* <div className="report--header small">
            <h3>My Exams</h3>
          </div> */}

          <div className="row">
            <Sidebar />
            <div className="cardsss col-lg-10 col-md-10 col-sm-12 col-xs-12">
              <div className="mt-3">
                <div className="Exams">
                  <div className="row">
                    <div className="col-12">
                      <div className="exam-tab">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                          <li className="nav-item">
                            <a
                              className="nav-link active"
                              id="home-tab"
                              data-toggle="tab"
                              href="#home"
                              role="tab"
                              onClick={() => {
                                let pagination = this.state.pagination;
                                pagination.status = "";
                                this.setState({ pagination });
                                this.getExamList();
                              }}
                              aria-controls="home"
                              aria-selected="true"
                            >
                              All Exams
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              id="profile-tab"
                              data-toggle="tab"
                              href="#profile"
                              role="tab"
                              onClick={() => {
                                let pagination = this.state.pagination;
                                pagination.status = "approval_awaited";
                                this.setState({ pagination });
                                this.getExamList();
                              }}
                              aria-controls="profile"
                              aria-selected="false"
                            >
                              Approval Awaiting
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              id="contact-tab"
                              data-toggle="tab"
                              href="#contact"
                              role="tab"
                              onClick={() => {
                                let pagination = this.state.pagination;
                                pagination.status = "creating";
                                this.setState({ pagination });
                                this.getExamList();
                              }}
                              aria-controls="contact"
                              aria-selected="false"
                            >
                              Creating Mode
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              id="approved-tab"
                              data-toggle="tab"
                              href="#approved"
                              role="tab"
                              onClick={() => {
                                let pagination = this.state.pagination;
                                pagination.status = "approved";
                                this.setState({ pagination });
                                this.getExamList();
                              }}
                              aria-controls="approved"
                              aria-selected="false"
                            >
                              Approved
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              id="active-tab"
                              data-toggle="tab"
                              href="#active"
                              role="tab"
                              onClick={() => {
                                let pagination = this.state.pagination;
                                pagination.status = "active";
                                this.setState({ pagination });
                                this.getExamList();
                              }}
                              aria-controls="active"
                              aria-selected="false"
                            >
                              Active
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              id="disabled-tab"
                              data-toggle="tab"
                              href="#disabled"
                              role="tab"
                              onClick={() => {
                                let pagination = this.state.pagination;
                                pagination.status = "disabled";
                                this.setState({ pagination });
                                this.getExamList();
                              }}
                              aria-controls="disabled"
                              aria-selected="false"
                            >
                              Disabled
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              id="correction-tab"
                              data-toggle="tab"
                              href="#correction"
                              role="tab"
                              onClick={() => {
                                let pagination = this.state.pagination;
                                pagination.status = "correction";
                                this.setState({ pagination });
                                this.getExamList();
                              }}
                              aria-controls="correction"
                              aria-selected="false"
                            >
                              Correction
                            </a>
                          </li>
                        </ul>
                        <div className="tab-content" id="myTabContent">
                          <div
                            className="tab-pane fade show active"
                            id="home"
                            role="tabpanel"
                            aria-labelledby="home-tab"
                          >
                            <div
                              className={
                                "row search-exam " +
                                (this.state.examResponse.length > 0
                                  ? "m6show"
                                  : "m6hidden")
                              }
                            >
                              <div className="col-md-4">
                                <input
                                  className="form-control"
                                  type="search"
                                  id="search"
                                  placeholder="Search Exams"
                                  onChange={this.pagination.bind(
                                    this,
                                    "search"
                                  )}
                                />
                              </div>
                            </div>
                            <br />
                            <div
                              className={
                                "row search-exam no-exams " +
                                (!this.state.examResponse.length ||
                                this.state.examResponse.length === 0
                                  ? "m6show"
                                  : "m6hidden")
                              }
                            >
                              <div className="col-12">No exams available</div>
                            </div>
                            {this.state.examResponse.map((examDetails, i) => {
                              return (
                                <div
                                  className="container tab-container mb-3"
                                  key={i}
                                >
                                  <div className="border border-info">
                                    <div className="row">
                                      <div className="col-md-5">
                                        <div className="exam-info">
                                          <b>Title:</b>
                                          {examDetails.exam_title}
                                          <br />
                                          <b>Style: </b>
                                          {examDetails.exam_style}
                                          <br />
                                          <b>Mode: </b>
                                          {examDetails.exam_mode === "public"
                                            ? "Premium"
                                            : examDetails.exam_mode ===
                                              "private"
                                            ? "Premium+"
                                            : "Free"}
                                          <br />
                                          <b>Type: </b>
                                          {examDetails.exam_type}
                                          <br />
                                          <b>Description: </b>
                                          {examDetails.exam_desc}
                                          <br />
                                          <b>Domain: </b>
                                          {examDetails.domain?.domain}
                                          <br />
                                          <b>Category: </b>
                                          {examDetails.catagory?.catagory}
                                          <br />
                                        </div>
                                      </div>
                                      <div className="col-md-3">
                                        <div className="exam-statusbar">
                                          <b>Status: </b>
                                          {examDetails.exam_status}
                                          <br />
                                          <br></br>
                                          <div className="progress">
                                            <div
                                              className="bg-success"
                                              style={{
                                                width: examDetails.progression,
                                              }}
                                              role="progressbar"
                                              aria-valuenow="100"
                                              aria-valuemin="0"
                                              aria-valuemax="100"
                                            ></div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-4 text-center">
                                        <button
                                          type="button"
                                          className={
                                            "btn btn-primary btn-sm w-75 mt-2 btn-ipad " +
                                            (this.state.pagination.status ===
                                              "creating" ||
                                            this.state.pagination.status ===
                                              "correction"
                                              ? "m6show"
                                              : "m6hidden")
                                          }
                                          onClick={() =>
                                            this.submitExamForApproval(
                                              examDetails.exam_id
                                            )
                                          }
                                        >
                                          Submit For Approval
                                        </button>
                                        <button
                                          type="button"
                                          className={
                                            "btn btn-warning btn-sm w-75 mt-2 btn-ipad   " +
                                            (this.state.pagination.status ===
                                            "creating"
                                              ? "m6show"
                                              : "m6hidden")
                                          }
                                          onClick={() =>
                                            this.handleModalShowHide(
                                              examDetails.exam_id
                                            )
                                          }
                                        >
                                          Assign Moderators
                                        </button>
                                        <br />
                                        <button
                                          type="button"
                                          className="btn btn-warning w-75 mt-2 btn-ipad"
                                          onClick={() =>
                                            this.getExamChat(
                                              examDetails.exam_id
                                            )
                                          }
                                        >
                                          Contact Admin
                                        </button>
                                        <br />
                                        <button
                                          type="button"
                                          className={
                                            "btn btn-secondary w-75 mt-2 btn-ipad  "
                                          }
                                          onClick={() =>
                                            this.modifyExam(examDetails.exam_id)
                                          }
                                        >
                                          Edit Exam
                                        </button>
                                        <button
                                          type="button"
                                          className="btn btn-primary w-75 mt-2 btn-ipad "
                                          data-toggle="modal"
                                          data-target=".bd-example-modal-lg"
                                          onClick={() =>
                                            this.getExamById(
                                              examDetails.exam_id
                                            )
                                          }
                                        >
                                          View Exam Details
                                        </button>

                                        <button
                                          type="button"
                                          className={
                                            "btn btn-danger w-75 mt-2 btn-ipad " +
                                            (this.state.pagination.status ===
                                              "creating" ||
                                            this.state.pagination.status ===
                                              "disabled"
                                              ? "m6show"
                                              : "m6hidden")
                                          }
                                          onClick={() =>
                                            this.deleteExam(examDetails.exam_id)
                                          }
                                        >
                                          Delete Exam
                                        </button>
                                        <br />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                            {this.state.pagination.totalPage < Number(2) ? (
                              <></>
                            ) : (
                              <Pagination
                                itemClass="page-item"
                                linkClass="page-link"
                                activePage={this.state.pagination.pageNo}
                                itemsCountPerPage={this.state.pagination.count}
                                totalItemsCount={Number(
                                  this.state.pagination.items
                                )}
                                pageRangeDisplayed={
                                  this.state.pagination.totalPage
                                }
                                onChange={this.handlePageChange.bind(this)}
                              />
                            )}
                          </div>
                        </div>
                        <Modal show={this.state.showHide}>
                          <Modal.Header
                            closeButton
                            onClick={() => this.handleModalShowHide(0)}
                          ></Modal.Header>
                          <Modal.Body>
                            <div className="row text-center">
                              <div className="col-12">
                                <div className="col-12">
                                  <h4>Assistants:</h4>
                                  <h6>
                                    (You can add up to 3 assistants email ids,
                                    on adding email id as assistant, he/she can
                                    edit this exam details untill the exam is in
                                    creating mode.)
                                  </h6>
                                  <div className="row text-center">
                                    <div className="col-1"></div>
                                    <div className="col-10 table-responsive">
                                      <table className="table table-bordered table-sm">
                                        <thead>
                                          <tr>
                                            <th scope="col">Sr. No.</th>
                                            <th scope="col">Email</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Change Status</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {this.state.assistants.map(
                                            (item, i) => (
                                              <tr key={i + "assistants"}>
                                                <th scope="row">{i + 1}</th>
                                                <td>{item.email}</td>
                                                <td>{item.validity}</td>
                                                <td>
                                                  <tr className="table-responsive text-wk-center">
                                                    <td>
                                                      <button
                                                        type="button"
                                                        className="btn btn-primary"
                                                        onClick={() => {
                                                          this.changeAssistantStatus(
                                                            item.email,
                                                            "delete",
                                                            "disabled"
                                                          );
                                                        }}
                                                      >
                                                        Disable
                                                      </button>
                                                    </td>
                                                    <td>
                                                      <button
                                                        type="button"
                                                        className="btn btn-primary"
                                                        onClick={() => {
                                                          this.changeAssistantStatus(
                                                            item.email,
                                                            "patch",
                                                            "suspended"
                                                          );
                                                        }}
                                                      >
                                                        Suspend
                                                      </button>
                                                    </td>
                                                    <td>
                                                      <button
                                                        type="button"
                                                        className="btn btn-primary"
                                                        onClick={() => {
                                                          this.changeAssistantStatus(
                                                            item.email,
                                                            "put",
                                                            "activated"
                                                          );
                                                        }}
                                                      >
                                                        Activate
                                                      </button>
                                                    </td>
                                                  </tr>
                                                </td>
                                              </tr>
                                            )
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                    <div className="col-1"></div>
                                  </div>
                                  <br />
                                  <br />
                                  <div
                                    className={
                                      "row " +
                                      (this.state.assistants.length < 3
                                        ? "m6show"
                                        : "m6hidden")
                                    }
                                  >
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center input-group flex-nowrap mb-4">
                                      <div className="input-group-prepend">
                                        <span
                                          className="input-group-text"
                                          id="addon-wrapping"
                                        >
                                          Email-
                                        </span>
                                      </div>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter assistant Email"
                                        aria-label="Instruction"
                                        aria-describedby="addon-wrapping"
                                        onChange={this.instructionDataChange.bind(
                                          this,
                                          "assistantEmail"
                                        )}
                                      />
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                                      <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() => {
                                          if (this.state.assistantsEmail) {
                                            let data = {
                                              email: [],
                                            };
                                            data.email.push(
                                              this.state.assistantsEmail
                                            );
                                            axios({
                                              method: "put",
                                              headers: {
                                                "Content-Type":
                                                  "application/json",
                                              },
                                              url:
                                                "/api/examstore/" +
                                                this.state.exam_id +
                                                "/assistants",
                                              data: data,
                                            })
                                              .then((response) => {
                                                if (
                                                  response.status === 201 ||
                                                  response.status === 200
                                                ) {
                                                  console.log(
                                                    "Success-Token is shared with the  provided email id"
                                                  );
                                                  this.getAssistantsList(
                                                    this.state.exam_id
                                                  );
                                                }
                                              })
                                              .catch((error) => {
                                                console.log(
                                                  error.response.data.errors[0]
                                                    .message
                                                );
                                              });
                                          } else {
                                            console.log("Enter valid email id");
                                          }
                                        }}
                                      >
                                        Add Assistants
                                      </button>
                                    </div>
                                  </div>
                                  <br />
                                </div>
                              </div>
                            </div>
                          </Modal.Body>
                          <Modal.Footer></Modal.Footer>
                        </Modal>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="modal fade bd-example-modal-lg"
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="myLargeModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                      <div className="card h-100 text-center border1">
                        <div className="row card-header exam-card">
                          <h5 className="col-md-11 col-sm-11 col-xs-6 modal-title margin">
                            Exam Preview
                          </h5>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                          <br></br>
                        </div>
                        <br></br>
                        <div className="col-md-12 col-sm-12">
                          <img
                            src={
                              this.state.ImgUrl +
                              this.state.examDetails.exam_image
                            }
                            alt="examarc.com"
                            className="react-player"
                            // width='100%'
                            // height='100%'
                            width="200px"
                            height="200px"
                          />
                        </div>
                        <div className="card-body">
                          {this.state.examDetails.exam_title ? (
                            <h5 className="card-title">
                              <b>{this.state.examDetails.exam_title}</b>
                            </h5>
                          ) : (
                            <></>
                          )}

                          {/* <div className=" container-fluid boxes  ">
                                        <div className="row">
                                            {this.state.examDomainDetails.domain ? (
                                                <div className=" col md-6 py-3">
                                                    Domain: <b>{this.state.newExamData.domain}</b>
                                                </div>
                                            ) : (
                                                    <></>
                                                )}
                                            {this.state.examCategoryDetails.catagory ? (
                                                <div className=" col md-6 py-3">
                                                    Category: <b>{this.state.newExamData.catagory}</b>
                                                </div>
                                            ) : (
                                                    <></>
                                                )}
                                        </div>
                                    </div > */}
                          {this.state.examDetails.exam_desc ? (
                            <p className="lead">
                              {this.state.examDetails.exam_desc}
                            </p>
                          ) : (
                            <></>
                          )}

                          <div className=" container-fluid boxes  ">
                            <div className="row">
                              {this.state.examDetails.exam_style ? (
                                <div className="col-md-4 py-3">
                                  <strong>Style:</strong>{" "}
                                  <p className="d-inline text-capitalize">
                                    {this.state.examDetails.exam_style}
                                  </p>
                                </div>
                              ) : (
                                <></>
                              )}
                              {this.state.examDetails.exam_mode ? (
                                <div className="col-md-4 py-3">
                                  <strong> Mode:</strong>{" "}
                                  <p className="d-inline text-capitalize">
                                    {this.state.examDetails.exam_mode}
                                  </p>
                                </div>
                              ) : (
                                <></>
                              )}
                              {this.state.examDetails.exam_type ? (
                                <div className="col-md-4 py-3">
                                  <strong>Type:</strong>{" "}
                                  <p className="d-inline text-capitalize">
                                    {this.state.examDetails.exam_type}
                                  </p>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                            <div className="row">
                              {this.state.examDetails.number_of_questions ? (
                                <div className="col-md-4 py-3">
                                  <strong>Questions: </strong>
                                  <b>
                                    {this.state.examDetails.number_of_questions}
                                  </b>
                                </div>
                              ) : (
                                <></>
                              )}
                              {this.state.examDetails.total_exam_score ? (
                                <div className="col-md-4 py-3">
                                  <strong>Marks: </strong>{" "}
                                  <p className="d-inline">
                                    {this.state.examDetails.total_exam_score}
                                  </p>
                                </div>
                              ) : (
                                <></>
                              )}
                              {this.state.examDetails.exam_duration ? (
                                <div className="col-md-4 py-3">
                                  <strong>Duration: </strong>
                                  <p className="d-inline">
                                    {this.state.examDetails.exam_duration} Min
                                  </p>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </div>
                        {this.state.examDetails.exam_status ? (
                          <div className="card-footer text-muted">
                            <strong>Status: </strong>{" "}
                            <p className="d-inline text-capitalize">
                              {this.state.examDetails.exam_status}
                            </p>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>

                      {this.state.questionsList.map((question) => {
                        return (
                          <div
                            key={question.question_number}
                            className="card h-100 text-center border1"
                          >
                            <div className="header">
                              <div className="row mt-4">
                                <div className="col-md-4">
                                  <strong>Question No.:</strong>{" "}
                                  <p className="d-inline">
                                    {question.question_number}
                                  </p>
                                </div>
                                {question.score ? (
                                  <div className=" col-md-4">
                                    <strong>Score:</strong>{" "}
                                    <p className="d-inline">{question.score}</p>
                                  </div>
                                ) : (
                                  <></>
                                )}
                                {question.negative_score ? (
                                  <div className=" col-md-4">
                                    <strong>Negative Score:</strong>{" "}
                                    <p className="d-inline">
                                      {question.negative_score}
                                    </p>
                                  </div>
                                ) : (
                                  <></>
                                )}
                                {question.question_domain ? (
                                  <div className=" col-md-4">
                                    <strong>Domain: </strong>
                                    <p className="d-inline text-capitalize">
                                      {question.question_domain}
                                    </p>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                            <div className="card-body qbody">
                              <blockquote className="blockquote mb-0">
                                <strong>
                                  Question :
                                  <p
                                    className="d-inline text-capitalize"
                                    dangerouslySetInnerHTML={{
                                      __html: question.question,
                                    }}
                                  />
                                </strong>
                              </blockquote>
                              <div className="row">
                                <div className=" col md-6 py-3">
                                  <strong>A:</strong>{" "}
                                  <p
                                    className="d-inline"
                                    dangerouslySetInnerHTML={{
                                      __html: question.answer_choice.a,
                                    }}
                                  />
                                  {/* <b>{question.answer_choice.a}</b> */}
                                </div>
                                <div className=" col md-6 py-3">
                                  <strong> B: </strong>
                                  <p
                                    className="d-inline"
                                    dangerouslySetInnerHTML={{
                                      __html: question.answer_choice.b,
                                    }}
                                  />
                                  {/* <b>{question.answer_choice.b}</b> */}
                                </div>
                              </div>

                              <div className="row">
                                <div className=" col md-6 py-3">
                                  {question.answer_choice.c == null ? (
                                    <></>
                                  ) : (
                                    <div>
                                      <strong>C: </strong>{" "}
                                      <p
                                        className="d-inline"
                                        dangerouslySetInnerHTML={{
                                          __html: question.answer_choice.c,
                                        }}
                                      />
                                    </div>
                                  )}
                                </div>
                                <div className=" col md-6 py-3">
                                  {question.answer_choice.d == null ? (
                                    <></>
                                  ) : (
                                    <div>
                                      <strong>D: </strong>{" "}
                                      <p
                                        className="d-inline"
                                        dangerouslySetInnerHTML={{
                                          __html: question.answer_choice.d,
                                        }}
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="row">
                                <div className=" col md-6 py-3">
                                  {question.answer_choice.e == null ? (
                                    <></>
                                  ) : (
                                    <div>
                                      <strong>E: </strong>
                                      <p
                                        className="d-inline"
                                        dangerouslySetInnerHTML={{
                                          __html: question.answer_choice.e,
                                        }}
                                      />
                                    </div>
                                  )}
                                </div>
                                <div className=" col md-6 py-3">
                                  {question.correct_answer == null ? (
                                    <></>
                                  ) : (
                                    <div>
                                      <strong>Correct Answer: </strong>{" "}
                                      <p
                                        className="d-inline text-capitalize"
                                        dangerouslySetInnerHTML={{
                                          __html: question.correct_answer,
                                        }}
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="row">
                                <div className=" col-md-12 py-auto">
                                  {question.reason == null ? (
                                    <></>
                                  ) : (
                                    <div>
                                      <strong>Explanation:</strong>{" "}
                                      <p
                                        className="d-inline text-left"
                                        dangerouslySetInnerHTML={{
                                          __html: question.reason,
                                        }}
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <br />
                          </div>
                        );
                      })}
                      <div className="modal-footer">
                        <div className="col-md-12 col-sm-12 col-xs-12 text-center">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            data-dismiss="modal"
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* *********modal for conversations********** */}
                <div
                  className="modal fade"
                  id="exampleModal"
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                          Chat
                        </h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div>
                          <div className="fixed-chat">
                            <div className="panel-chat">
                              <div className="body-chat"></div>
                              <div className="message-chat"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer footerMessage"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
