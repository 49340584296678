import React, { Component } from "react";
import "react-multi-carousel/lib/styles.css";
import "./Card-style.css";
import axios from "axios";

import AppConstants from "../../Constants/AppConstants";

class PopularInstructors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      instArray: [],
      instructorDetails: {},
      finalInst: [],
      instEmpty: false,
    };
  }

  componentDidMount() {
    axios({
      method: "GET",
      url: "/api/popular?type=instructors&top=10",
    }).then((response) => {
      if (response.status === 200) {
        let data = response.data;
        //let array = [];
        let instArray = data.popular.instructors;
        if (instArray.length === 0) {
          this.setState({ instEmpty: true });
        } else {
          this.setState({ instArray: instArray });
        }
      } else {
        console.log("Unable to fetch top 8 instructors");
      }
    });
  }

  render() {
    return (
      <div>
        {this.state.instEmpty === false ? (
          <div className="container">
            <div className=" mr-4 top-educators ">
              <br />
              <div className="row">
                <div className="col-md-9 top-educators-left">
                  <h6 className="font-mob">
                    Prepare under the guidence of Experienced Professionals
                  </h6>
                  <hr></hr>
                  <p className="para-mob">
                    Access to best talents in technology is a subscription away
                  </p>
                </div>
                <div className="col-md-3 top-educators-right">
                  <br />
                  <br />
                  <a className="text-decoration-none" href="/instructors">
                    <p className="card-seeall">View More</p>
                  </a>
                </div>
              </div>
              <br />
            </div>

            <div className="row">
              {this.state.instArray.map((inst) => {
                return (
                  <div
                    className={this.state.isTablet ? "col-4" : "col-md-3 my-3"}
                    key={inst.instructor_id}
                  >
                    <div
                      className="card mt"
                      href={
                        "/instructor-page?instructorId=" + inst.instructor_id
                      }
                    >
                      <a
                        className="text-decoration-none"
                        href={
                          "/instructor-page?instructorId=" + inst.instructor_id
                        }
                      >
                        <div className="avatar">
                          <figure className="image">
                            <img
                              src={inst.instructor_info.profile_image}
                              alt="profile_image"
                              height={
                                AppConstants.POPULAR_INSTRUCTOR_HEIGHT_LANDING
                              }
                              width={AppConstants.POPULAR_CATEGORIES_WIDTH}
                            />
                          </figure>
                        </div>
                        <div>
                          <p className="text-dark text-center">
                            {inst.instructor_info.first_name}{" "}
                            {inst.instructor_info.last_name}
                          </p>
                        </div>
                      </a>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    );
  }
}

export default PopularInstructors;
