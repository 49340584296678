import axios from "axios";
import React, { Component } from "react";
import Loader from "react-loader-spinner";
import Sidebar from "../../../Sidebar";
import AppConstants from "../../../../Constants/AppConstants";

class DomainWiseCategoryList extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      details: [],
      loading: true,
      exams_partof: [],
      domain: "",
      domainId: "",
      domainName: "",
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.setState({ loading: true });
    this.checkUser();
    this.initData();
  }

  checkUser() {
    axios({
      method: "GET",
      url: "/api/users/currentuser",
    })
      .then((response) => {
        if (response.data.current_user) {
          // If a user is logged in, update the state
          this.setState({ userIsLoggedIn: true });
        } else {
          // If no user is logged in, ensure userIsLoggedIn is false
          this.setState({ userIsLoggedIn: false });
        }
      })
      .catch((error) => {
        console.log(
          error.response?.data?.errors?.[0]?.message || "An error occurred"
        );
        this.setState({ userIsLoggedIn: false }); // Set false in case of an error
      });
  }

  initData() {
    const searchParams = new URLSearchParams(this.props.location.search);
    const domainId = searchParams.get("search");

    if (domainId) {
      this.setState({ domainId });
      this.getCategories(domainId);
    } else {
      const storedDomainId = sessionStorage.getItem("domainSelected");
      this.setState({ domainId: storedDomainId });
      this.getCategories(storedDomainId);
    }
  }

  componentDidUpdate(prevProps) {
    // Check if the "search" prop has changed
    if (this.props.location.search !== prevProps.location.search) {
      this.initData();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getCategories(search) {
    // Accept search as a parameter
    // Check if search is defined and use it as domainId, otherwise fall back to sessionStorage
    const domainId = search || sessionStorage.getItem("domainSelected");

    if (domainId) {
      this.setState({ domainId });
      axios({
        method: "Get",
        url: `/api/popular?type=domains&type_id=${domainId}`,
        headers: {
          "Content-Type": "application/json",
        },
      }).then((response) => {
        let data = response.data;
        if (data.popular.domain) {
          this.setState({ loading: false });
          if (data.popular.domain) {
            this.setState({ details: data.popular.domain.categories });
          }
          if (data.popular.domain && data.popular.domain.exams_partof) {
            this.setState({ exams_partof: data.popular.domain.exams_partof });
          } else {
            this.setState({ exams_partof: [] });
          }
        } else {
          this.setState({ exams_partof: [] });
        }
      });
    } else {
      alert("No data available");
    }
  }

  render() {
    if (this.state.loading) {
      return (
        <Loader
          className="text-center"
          type="Puff"
          color="#00BFFF"
          height={500}
          width={100}
          timeout={5000}
        />
      );
    } else {
      return (
        <div className="container">
          <div className="row">
            {this.state.userIsLoggedIn && <Sidebar />}
            <div className="col-lg-9 col-md-8 col-sm-12">
              <h6 className="mt-3">Category: {this.state.domainName}</h6>
              <hr />
              <div className="row">
                {!this.state.details
                  ? "Loading..."
                  : this.state.details.map((category) => (
                      <div
                        className="col-12 col-sm-6 col-md-4 col-lg-3 mb-3"
                        key={category.category_id}
                      >
                        <div className="card mt">
                          <a
                            className="text-decoration-none"
                            href={`/category-page?categoryId=${category.category_id}`}
                          >
                            <div className="text-center">
                              <img
                                src={
                                  AppConstants.IMG_URL + category.category_image
                                }
                                alt="category"
                                height={AppConstants.POPULAR_CATEGORIES_HEIGHT}
                                width={AppConstants.POPULAR_CATEGORIES_WIDTH}
                              />
                            </div>
                            <div>
                              <p className="text-dark text-center">
                                {category.category_name}
                              </p>
                            </div>
                          </a>
                        </div>
                      </div>
                    ))}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default DomainWiseCategoryList;
