import React from "react";
import ReactTextTransition, { presets } from "react-text-transition";
import "./Transition.css";
const Scrollheadlines = [
  "Prepare for exams, build your investing and trading skills.",
  "Experience time management & pressure of exams",
  "Exams from proven faculty and previous questions",
];

export const TypeAnimation = () => {
  const [index, setIndex] = React.useState(0);

  // componentDidMount() {
  //   setInterval(() => {
  //     this.setState({
  //       textIndex: this.state.textIndex + 1,
  //     });
  //   }, 40);
  // }
  //https://stackoverflow.com/questions/54954385/react-useeffect-causing-cant-perform-a-react-state-update-on-an-unmounted-comp?answertab=modifieddesc#tab-top
  React.useEffect(() => {
    let abortController = new AbortController();
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      3000 // every 3 seconds
    );

    return () => {
      clearTimeout(intervalId);
      abortController.abort();
    };
  }, []);

  return (
    <React.Fragment>
      <section className="inline mr-0">
        <ReactTextTransition
          text={Scrollheadlines[index % Scrollheadlines.length]}
          spring={presets.gentle}
          // className="big"
          // delay={300}
        />
      </section>
    </React.Fragment>
  );
};
